import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function UpdateWorkerTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'UWT',
      productName: 'Formazione lavoratore AGGIORNAMENTO',
      productImages: {
        0: ['courses/corso-formazione-lavoratore-aggiornamento.jpg', 'corso formazione lavoratore aggiornamento'],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '4000',
      productPriceDesc: null,
      price: 'price_1J0oAELde8wm4I0oLZkih5fW',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i lavoratori di qualsiasi tipologia di azienda ogni 5 anni.<br/><br/> <strong>DURATA:</strong> 6 h<br/>'

  const presentation = {
    title: 'Formazione lavoratore AGGIORNAMENTO',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> Come previsto dall’Accordo Stato Regioni del 21.12.2011, il corso fornisce ai partecipanti gli approfondimenti e aggiornamenti giuridico-normativi, aggiornamenti tecnici sui rischi e su organizzazione e gestione della sicurezza in azienda.',
    photo: 'corso-formazione-generale-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
